<template>
  <div
    v-if="!inAgeRestrictedLocation && dealsDataStatus === 'success'"
    class="flex flex-row items-center gap-2"
  >
    <div class="block md:hidden">
      <AdsOneClickDeal
        v-if="singleMobileAd.bonusOffer && dealsData.availableOffer"
        :offer="dealsData.availableOffer"
        :width="300"
        :height="250"
      />
      <AdsSquare v-else :space-id="singleMobileAd.spaceId" />
    </div>

    <div class="hidden lg:flex flex-row items-center justify-center space-x-4">
      <AdsOneClickDeal
        v-if="dealsData.availableOffer && hasBanner && bannerDisplayRate"
        :offer="dealsData.availableOffer"
        :width="900"
        :height="250"
      />
      <AdsSquare
        v-for="ad in availableNonMobileAds"
        v-else
        :key="ad.spaceId"
        :space-id="ad.spaceId"
      />
    </div>
  </div>
</template>

<script setup>
  const currentSite = inject("currentSite")
  const inAgeRestrictedLocation = inject("inAgeRestrictedLocation")
  const bannerDisplayRate = 0.33

  const availableAds = computed(() => {
    if (currentSite.value.installation === "bp") {
      return [
        {
          spaceId: 11510642,
          mobile: true,
          bonusOffer: true,
        },
        {
          spaceId: 11510633,
          mobile: false,
          bonusOffer: true,
        },
        {
          spaceId: 11510636,
          mobile: false,
          bonusOffer: true,
        },
        {
          spaceId: 11510639,
          mobile: false,
          bonusOffer: false,
        },
      ]
    } else if (currentSite.value.slug === "wetvr") {
      return [
        {
          spaceId: 11507252,
          mobile: true,
          bonusOffer: true,
        },
        {
          spaceId: 11507252,
          mobile: false,
          bonusOffer: true,
        },
        {
          spaceId: 11507339,
          mobile: false,
          bonusOffer: false,
        },
        {
          spaceId: 11507258,
          mobile: false,
          bonusOffer: false,
        },
      ]
    } else {
      return [
        {
          spaceId: 11510630,
          mobile: true,
          bonusOffer: true,
        },
        {
          spaceId: 11507252,
          mobile: false,
          bonusOffer: true,
        },
        {
          spaceId: 11507255,
          mobile: false,
          bonusOffer: false,
        },
        {
          spaceId: 11507258,
          mobile: false,
          bonusOffer: false,
        },
      ]
    }
  })

  const availableMobileAds = computed(() => {
    return availableAds.value.filter((ad) => ad.mobile)
  })

  const availableNonMobileAds = computed(() => {
    return availableAds.value.filter((ad) => !ad.mobile)
  })

  const singleMobileAd =
    availableMobileAds.value[
      Math.floor(Math.random() * availableMobileAds.value.length)
    ]

  const {
    data: dealsData,
    execute: executeDealsData,
    status: dealsDataStatus,
  } = useApi("/api/deals/", {
    server: false,
    immediate: false,
    query: { placement: "footer-ads" },
  })

  const hasBanner = computed(() => {
    const offer = dealsData.value.availableOffer

    if (
      offer.images.filter((image) => image.dimensions === "900x250").length > 0
    ) {
      return true
    }

    if (
      offer.videos.filter((image) => image.dimensions === "900x250").length > 0
    ) {
      return true
    }

    return false
  })

  onMounted(() => {
    if (!inAgeRestrictedLocation) {
      executeDealsData()
    }
  })
</script>
